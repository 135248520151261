import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1White, B2White, colors, medWrapper } from "../styles/helpers"

import Twitter from "./Icons/Twitter"
import Facebook from "./Icons/Facebook"
import Instagram from "./Icons/Instagram"

const getData = graphql`
  {
    footerData: wp {
      acfOptionsSiteWideSettings {
        acfSiteWideSettings {
          instagramUrl
          twitterUrl
          facebookUrl
          phoneNumber
          footerTreatments
          footerNewPatient
          footerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(getData)
  const {
    instagramUrl,
    facebookUrl,
    twitterUrl,
    phoneNumber,
    footerTreatments,
    footerNewPatient,
    footerLogo,
  } = data.footerData.acfOptionsSiteWideSettings.acfSiteWideSettings

  const image = getImage(footerLogo.localFile.childImageSharp.gatsbyImageData)
  const logoAlt = footerLogo.altText

  return (
    <FooterStyled>
      <div className="wrapper">
        <div className="foot-content">
          <div className="foot-content__item foot-logo">
            <GatsbyImage image={image} alt={logoAlt} layout="fixed" />
          </div>
          <div className="foot-content__item foot-book">
            <h3>Book a Consult</h3>
            <p>
              Call or Text <br />
              <a href={`tel:+1-${phoneNumber}`}>{phoneNumber}</a>
            </p>

            <ul>
              <StyledIcon>
                <a
                  title="Follow us on Facebook - Link will open in new window"
                  target="_blank"
                  rel="noreferrer"
                  href={facebookUrl}
                >
                  <i>
                    <Facebook />
                    <span className="visuallyhidden">Facebook</span>
                  </i>
                </a>
              </StyledIcon>

              <StyledIcon>
                <a
                  title="Follow us on Instagram - Link will open in new window"
                  target="_blank"
                  rel="noreferrer"
                  href={instagramUrl}
                >
                  <i>
                    <Instagram />
                    <span className="visuallyhidden">Instagram</span>
                  </i>
                </a>
              </StyledIcon>

              <StyledIcon>
                <a
                  target="_blank"
                  rel="noreferrer"
                  title="Follow us on Twitter - Link will open in new window"
                  href={twitterUrl}
                >
                  <i>
                    <Twitter />
                    <span className="visuallyhidden">Twitter</span>
                  </i>
                </a>
              </StyledIcon>
            </ul>
          </div>
          <div className="foot-content__item foot-treatments">
            <h3>Treatments</h3>
            <p>{footerTreatments}</p>
          </div>
          <div className="foot-content__item foot-patient">
            <h3>Are you a new patient?</h3>
            <p>{footerNewPatient}</p>
            <Link to="/getting-started">Getting Started</Link>
          </div>
        </div>
        <div className="copy">
          <p>
            Quest Braces © {new Date().getFullYear()}. Designed, and developed
            by{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://switchbackcreative.ca/"
            >
              Switchback Creative
            </a>
            , Built with ♡ and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.gatsbyjs.com"
            >
              Gatsby
            </a>
            {" -- "}
            <Link to="/disclaimer">Disclaimer</Link>
            {" | "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
        </div>
      </div>
    </FooterStyled>
  )
}

const FooterStyled = styled.footer`
  width: 100%;
  background-color: ${colors.colorPrimary};

  .wrapper {
    ${medWrapper};
  }

  .foot-content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.5rem;

    &__item {
      width: 100%;
      margin-bottom: 3.5rem;

      @media (min-width: 768px) {
        width: calc(25%);
        margin-bottom: 0;
        padding: 0 2rem;
      }

      @media (min-width: 1025px) {
        width: calc(25%);
        padding: 0 2rem;
      }

      h3 {
        ${B1White};
        font-weight: bold;
        margin-bottom: 2.5rem;
        text-align: center;

        @media (min-width: 768px) {
          text-align: left;
        }
      }

      p,
      a {
        ${B2White};
        margin: 0;
        text-align: center;

        @media (min-width: 768px) {
          text-align: left;
        }
      }

      a:hover {
        color: ${colors.colorTertiary};
      }

      ul {
        margin-top: 3.5rem;
        text-align: center;

        @media (min-width: 768px) {
          margin-top: 1rem;
          text-align: left;
        }

        li:first-of-type {
          margin-left: 0;
        }
      }
    }

    .foot-patient {
      text-align: center;
      @media (min-width: 768px) {
        text-align: left;
      }
      a {
        display: inline-block;
        padding-top: 1rem;
      }
    }

    .foot-logo {
      max-width: 15rem;
      margin: 2.5rem auto 5rem;

      @media (min-width: 768px) {
        max-width: 10rem;
        margin: auto;
        padding: 0;
      }

      @media (min-width: 1025px) {
        padding-top: 1.5rem;
      }
    }
  }

  .copy {
    width: 100%;

    p,
    a {
      ${B2White};
      margin: 0;
      text-align: center;
    }

    a:hover {
      color: ${colors.colorTertiary};
    }
  }
`

const StyledIcon = styled.li`
  display: inline-block;
  margin-right: 0.5rem;
  margin-left: 0.5rem;

  a {
    display: block;
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    @media (min-width: 768px) {
      width: 2rem;
      height: 2rem;
    }
    @media (min-width: 1025px) {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:focus {
      outline: 0.4rem solid #003b49;
      transition: outline-width 0.35s ease-in-out;
    }

    .visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: auto;
      transition: all 0.3s ease-out;
      fill: ${colors.white};

      @media (min-width: 768px) {
        width: 2rem;
        height: 2rem;
      }
      @media (min-width: 1025px) {
        width: 1.5rem;
        height: 1.5rem;
      }

      &:hover {
        fill: ${colors.colorTertiary};
      }
    }
  }
`

export default Footer
