import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { medWrapper, colors, B2LightBlue } from "../../../styles/helpers"
import HeaderMenuMain from "./HeaderMenuMain"

import Twitter from "../../Icons/Twitter"
import Facebook from "../../Icons/Facebook"
import Instagram from "../../Icons/Instagram"

const getData = graphql`
  {
    headerData: wp {
      acfOptionsSiteWideSettings {
        acfSiteWideSettings {
          instagramUrl
          twitterUrl
          facebookUrl
          phoneNumber
        }
      }
    }
  }
`

const HeaderNav = () => {
  const data = useStaticQuery(getData)
  const { instagramUrl, facebookUrl, twitterUrl, phoneNumber } =
    data.headerData.acfOptionsSiteWideSettings.acfSiteWideSettings
  return (
    <HeaderNavStyled>
      <div className="navTop">
        <p>
          Call or Text <a href={`tel:+1-${phoneNumber}`}>{phoneNumber}</a>
        </p>
        <ul>
          <StyledIcon>
            <a
              title="Follow us on Facebook - Link will open in new window"
              target="_blank"
              rel="noreferrer"
              href={facebookUrl}
            >
              <i>
                <Facebook />
                <span className="visuallyhidden">Facebook</span>
              </i>
            </a>
          </StyledIcon>

          <StyledIcon>
            <a
              title="Follow us on Instagram - Link will open in new window"
              target="_blank"
              rel="noreferrer"
              href={instagramUrl}
            >
              <i>
                <Instagram />
                <span className="visuallyhidden">Instagram</span>
              </i>
            </a>
          </StyledIcon>

          <StyledIcon>
            <a
              target="_blank"
              rel="noreferrer"
              title="Follow us on Twitter - Link will open in new window"
              href={twitterUrl}
            >
              <i>
                <Twitter />
                <span className="visuallyhidden">Twitter</span>
              </i>
            </a>
          </StyledIcon>
        </ul>
      </div>
      <div className="navBot">
        <div className="navBot__wrapper">
          <HeaderMenuMain />
        </div>
      </div>
    </HeaderNavStyled>
  )
}

const HeaderNavStyled = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1025px) {
    width: 85%;
  }

  @media (min-width: 1100px) {
    width: 80%;
  }

  .navTop {
    ${medWrapper};
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin-top: 2.5rem;

    @media (min-width: 768px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    @media (min-width: 1025px) {
      justify-content: flex-end;
      margin-top: 0;
      margin-bottom: 0;
    }

    p,
    a {
      ${B2LightBlue};
      margin: 0;
    }

    a:hover {
      color: ${colors.colorAccent};
    }

    ul {
      margin-left: 2.5rem;
    }
  }

  .navBot {
    ${medWrapper};
    max-width: 100% !important;
    justify-content: center;
    width: 100%;
    padding: 0;

    @media (min-width: 1025px) {
      justify-content: flex-end;
    }
  }
`

const StyledIcon = styled.li`
  display: inline-block;
  margin-right: 0.5rem;
  margin-left: 0.5rem;

  a {
    display: block;
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    @media (min-width: 768px) {
      width: 3.5rem;
      height: 3.5rem;
    }
    @media (min-width: 1025px) {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:focus {
      outline: 0.4rem solid #003b49;
      transition: outline-width 0.35s ease-in-out;
    }

    .visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: auto;
      transition: all 0.3s ease-out;
      fill: ${colors.colorSecondary};

      @media (min-width: 768px) {
        width: 3.5rem;
        height: 3.5rem;
      }
      @media (min-width: 1025px) {
        width: 2.4rem;
        height: 2.4rem;
      }

      &:hover {
        fill: ${colors.colorAccent};
      }
    }
  }
`

export default HeaderNav
